<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import { useApiShareLeaseSnippet } from '~/composables/useAPI/useApiShareLeaseSnippet'
import { VueFinalModal } from 'vue-final-modal'
import { toast } from 'vue3-toastify'
import { ApiError } from '@shared'

interface Props {
  snippet?: string
  page?: number
}
const props = withDefaults(defineProps<Props>(), {
  snippet: undefined,
  page: undefined,
})

const emit = defineEmits(['close', 'send'])
const items = ref<string[]>([])

function addTag(tag: string): void {
  items.value.push(tag)
}
const shareLeaseSnippet = useApiShareLeaseSnippet()

const form = ref<typeof FormKit>(null!)

function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const submitForm = async (data: any) => {
  const node = form.value?.node
  try {
    const body = {
      recipients: data.emails
        .split(',')
        .map((email: string) => email.trim())
        .filter((email: string) => isValidEmail(email)),
      subject: data.subject ?? 'Document lease snippet',
      content: data.body,
      image: props.snippet,
    }
    await shareLeaseSnippet.mutateAsync(body)

    toast.success('Snippet sent')
  } catch (e) {
    node.setErrors(['Something went wrong'])
    if (e instanceof ApiError && e.data) {
      const { localErrors, childErrors } = requestToFormkitErrors(e.data)
      node.setErrors(localErrors, childErrors)
      toast.error('Error occurs while sending the snippet')
    }
  }
}
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="
      {
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': '-translate-y-6 opacity-0',
        'enter-to-class': 'translate-y-0 opacity-100',
        'leave-active-class': 'transition duration-300 ease-in-out transform',
        'leave-to-class': 'translate-y-6 opacity-0',
        'leave-from-class': '-translate-y-0 opacity-100',
      } as any
    "
  >
    <ModalContainer
      title="Share a snippet"
      close-on-click
      @close="emit('close')"
    >
      <FormKit
        ref="form"
        type="form"
        :actions="false"
        class="flex"
        @submit="submitForm"
      >
        <FormKit
          name="emails"
          type="text"
          :multiple="true"
          help="Add multiple emails separated by comma"
          label="Email"
          track-by="code"
          outer-class="w-full mt-6"
        />

        <FormKit
          type="text"
          name="subject"
          label="Subject"
          outer-class="w-full mt-6"
        />

        <FormKit
          type="textarea"
          name="body"
          label="Body"
          auto-height
          outer-class="w-full mt-6"
        />
        <div class="relative my-6 rounded-lg bg-zinc-100 p-1 dark:bg-zinc-600">
          <img :src="props.snippet" alt="Snippet" class="w-full rounded-md" />
        </div>
        <div class="mt-6 flex justify-end">
          <Button
            type="submit"
            :loading="form?.node.context.state.loading"
            class="mr-2"
            @click.prevent="form?.node.submit()"
          >
            Send
          </Button>
        </div>
      </FormKit>
    </ModalContainer>
  </VueFinalModal>
</template>
