import { useMutation } from '@tanstack/vue-query'
import type { ISendEmailRequest } from './types'

export function useApiShareLeaseSnippet() {
  return useMutation({
    mutationFn: (body: ISendEmailRequest) =>
      fetchAPI(`/email/leaseSnippet`, {
        method: 'POST',
        body,
      }),
  })
}
